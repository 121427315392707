.strength-checker {
  font-size: 13px;
  position: absolute;
  bottom: 28px;
  left: 22px;
  z-index: 10;
  padding: 0 5px;
}

.checker-bg-light {
  background-color: white;
}

.checker-bg-dark {
  background-color: #343a40;
}

.password-input-group {
  position: relative;
}

.password-generator-icon {
  position: absolute;
  right: 15px;
  bottom: 12px;
  cursor: pointer;
  z-index: 10;
}
