@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700'); */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;500&family=Rubik:wght@300;400;500;600&display=swap');


/* @import '~react-toastify/scss/main'; */

#root {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}
.login-page,.register-page {
    background: url('./assets/img/auth-bg.jpg') no-repeat center center;
}
.lh-1 {
    line-height: 1;
}
.dc-m-h {
    max-height: 555px;;
}