.uploadForm {
  padding: 35px 0;
}
.fileUpload {
  display: block;
  text-align: center;
  font-size: 12px;
}
.fileUpload .fileSelect {
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.fileUpload .fileSelect .fileSelectButton {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.fileUpload .fileSelect .fileSelectName {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
.fileUpload .fileSelect:hover {
  transition: all 0.2s ease-in-out;
}
.fileUpload .fileSelect:hover .fileSelectButton {
  cursor: pointer;
  background: #b7c0ca;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.fileUpload .fileSelect input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.fileUpload .fileSelect.fileSelectDisabled {
  opacity: 0.65;
}
.fileUpload .fileSelect.fileSelectDisabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #7d7f82;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.fileUpload .fileSelect.fileSelectDisabled:hover .fileSelectButton {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.fileUpload .fileSelect.fileSelectDisabled:hover .fileSelectName {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
.upload-button {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #3498db;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #2980b9;
}
