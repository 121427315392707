.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #007bff;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.password_generator_icon {
  position: absolute;
  right: 50px;
  bottom: 12px;
  cursor: pointer;
  z-index: 10;
}

.country_placeholder {
  color: #ced4da;
}
