/* common */
.loading {
    height: calc(100vh - calc(4.5rem + 1px) - calc(3.5rem + 1px));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-align: center;
}
@media screen and (max-width: 550px) {
    .loading {
        font-size: 20px;
    }
}
.loading span {
    display: inline-block;
    color: #f2712a;
}
.loading span {
    margin: 0 0.075em;
    animation: loadingShrink 0.7s infinite alternate;
}
.loading span:nth-child(2) {
    animation-delay: 0.1s;
}
.loading span:nth-child(3) {
    animation-delay: 0.2s;
}
.loading span:nth-child(4) {
    animation-delay: 0.3s;
}
.loading span:nth-child(5) {
    animation-delay: 0.4s;
}
.loading span:nth-child(6) {
    animation-delay: 0.5s;
}
.loading span:nth-child(7) {
    animation-delay: 0.6s;
}
.loading span:nth-child(8) {
    animation-delay: 0.7s;
}
.loading span:nth-child(9) {
    animation-delay: 0.8s;
}
.loading span:nth-child(10) {
    animation-delay: 0.9s;
}
.loading span:nth-child(11) {
    animation-delay: 1.0s;
}
.loading span:nth-child(12) {
    animation-delay: 1.1s;
}
.loading span:nth-child(13) {
    animation-delay: 1.2s;
}
.loading span:nth-child(14) {
    animation-delay: 1.3s;
}
@keyframes loadingShrink {
    0% {
        transform: scale(1);
   }
    100% {
        transform: scale(0.8);
   }
}
