.selectbox {
    padding: 10px;
    line-height: 16px;
    border-width: 0 1px 4px;
    border: 1px solid #A1B3C1;
    cursor: pointer;
}
.filterBlock {
    list-style: none;
    padding: 5px 10px;
}
.filterButton { 
    height: 38px;
    border-radius: unset;
}
.source-logo {
    max-height: 20px;;
}
