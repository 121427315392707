.spinner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b2b1b0ab;
}
