.card {
    margin-bottom: 1.875rem;
    border: none;
    border-radius: 0;
    box-shadow: 0 10px 40px 0 rgb(62 57 107 / 7%), 0 2px 9px 0 rgb(62 57 107 / 6%);
}
.icon{
    font-size: 3rem;
    color:#fff
}
.numbers {
    text-align: right;
    font-size: 1.5rem;
}
.card-category {
    color: #9a9a9a;
    font-size: 16px;
    line-height: 1.4em;
}
 .numbers p {
    margin-bottom: 0;
}
.card-footer {
    padding: 0 15px 15px;
}
.card-body {
    padding: 15px 15px 0;
}
.card-footer .stats {
    color: #9a9a9a;
}
.card-footer .stats i {
    position: relative;
    top: 0;
    color: #66615b;
    margin-right: 9px;
}

.icon-big {
    font-size: 3rem;
}