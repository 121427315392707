.refLink {
    padding: 3px 0 7px 5px;
}
.refLinkBtn {
    margin: 0 5px;
}

@media screen and (max-width:500px){
    .refLinkBtn {
        margin: 5px 0;
    }
}