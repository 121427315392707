.code-context pre {
    margin: 0;
    padding: .2rem .75rem;
}
.code-context pre code{
    font-size: 87.5%;
}
.add-banner-block{
    min-height: 177px;
}
.zones-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.zone-item {
    list-style: none;
}